import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { signOut } from "supertokens-auth-react/recipe/session";
import { esbDetails } from "../config";
// import CallAPIView from "./CallAPIView";
import { BlogsIcon, CelebrateIcon, GuideIcon, SeparatorLine, SignOutIcon } from "../assets/images";

interface ILink {
    name: string;
    onClick: () => void;
    icon: string;
}

export default function SuccessView(props: { userId: string }) {
    let userId = props.userId;

    const navigate = useNavigate();

    useEffect(() => {
        const timer = setTimeout(() => {
            window.location.href = esbDetails.esbAppLink;
        }, 3000);
        return () => clearTimeout(timer);
    }, []);

    async function logoutClicked() {
        await signOut();
        navigate("/");
    }

    function openLink(url: string) {
        window.open(url, "_blank");
    }

    const links: ILink[] = [
        {
            name: "ERAG",
            onClick: () => openLink(esbDetails.esbAppLink),
            icon: BlogsIcon,
        },
        {
            name: "API Documentation",
            onClick: () => openLink(esbDetails.apiDocsLink),
            icon: GuideIcon,
        },
        {
            name: "Sign Out",
            onClick: logoutClicked,
            icon: SignOutIcon,
        },
    ];

    return (
        <>
            <div className="main-container">
                <div className="top-band success-title bold-500">
                    <img src={CelebrateIcon} alt="Login successful" className="success-icon" /> Login successful
                </div>
                <div className="inner-content">
                    <div>Welcome {userId}</div>
                    <div></div>
                    <div>
                        <button className="proceed-button" style={{
                            backgroundColor: '#ff9933', color: 'white', borderRadius: '5px',
                            padding: '10px 20px', fontSize: '16px', border: 'none', cursor: 'pointer'
                            }} onClick={() => { window.location.href = esbDetails.esbAppLink; }}>
                            Click here if you are not automatically redirected.
                        </button>
                    </div>
                </div>
            </div>
            <div className="bottom-links-container">
                {links.map((link) => (
                    <div className="link" key={link.name}>
                        <img className="link-icon" src={link.icon} alt={link.name} />
                        <div role={"button"} onClick={link.onClick}>
                            {link.name}
                        </div>
                    </div>
                ))}
            </div>
            <img className="separator-line" src={SeparatorLine} alt="separator" />
        </>
    );
}
