import ThirdPartyEmailPassword, {
    Google,
} from "supertokens-auth-react/recipe/thirdpartyemailpassword";
import EmailVerification from "supertokens-auth-react/recipe/emailverification";
import { ThirdPartyEmailPasswordPreBuiltUI } from "supertokens-auth-react/recipe/thirdpartyemailpassword/prebuiltui";
import { EmailVerificationPreBuiltUI } from "supertokens-auth-react/recipe/emailverification/prebuiltui";
import Session from "supertokens-auth-react/recipe/session";

export function getApiDomain() {
    const apiPort = process.env.REACT_APP_API_PORT || 8000;
    const apiUrl = process.env.REACT_APP_API_URL || `http://localhost:${apiPort}`;
    return apiUrl;
}

export function getWebsiteDomain() {
    const websitePort = process.env.REACT_APP_WEBSITE_PORT || 8000;
    const websiteUrl = process.env.REACT_APP_WEBSITE_URL || `http://localhost:${websitePort}`;
    return websiteUrl;
}

export const SuperTokensConfig = {
    appInfo: {
        appName: "ESB",
        apiDomain: getApiDomain(),
        apiBasePath: "/auth",
        websiteDomain: getWebsiteDomain(),
        websiteBasePath: "/st/ui/auth",
    },
    // recipeList contains all the modules that you want to
    // use from SuperTokens. See the full list here: https://supertokens.com/docs/guides
    recipeList: [
        ThirdPartyEmailPassword.init({
            useShadowDom: false,
            // getRedirectionURL: async (context) => {
            //     if (context.action === "SUCCESS") {
            //         if (context.redirectToPath !== undefined) {
            //             // we are navigating back to where the user was before they authenticated
            //             return context.redirectToPath;
            //         }
            //         return "/app/";
            //     }
            //     return undefined;
            // },
            signInAndUpFeature: {
                style: `
                    [data-supertokens~=headerSubtitle] {
                        display: none;
                    }
                    [data-supertokens~=superTokensBranding] {
                        display: none;
                    }
                `,
                providers: [Google.init()],
                signUpForm: {
                    termsOfServiceLink: "https://trilogy.com/terms-of-use/",
                    privacyPolicyLink: "https://trilogy.com/privacy-policy/"
                }
            },
        }),
        EmailVerification.init({
            mode: "REQUIRED",
          }),
        Session.init(),
    ],
};

export const esbDetails = {
    esbAppLink: "/",
    apiDocsLink: "https://erag.trilogy.com/api/docs",
};

export const PreBuiltUIList = [ThirdPartyEmailPasswordPreBuiltUI, EmailVerificationPreBuiltUI];

export const ComponentWrapper = (props: { children: JSX.Element }): JSX.Element => {
    return props.children;
};
